import React from "react"

import { GlobalStyle } from "../Shared/Global.styles"
import {
  HomeContainer,
  HomeContentContainer,
  MapletonLogoImage
} from "../components/Home.styles"

import SEO from "../components/seo"
import MapletonLogo from "../images/mapleton-logo.png"
import ComingSoon from "../components/ComingSoon.component"

const App = () => (
  <>
    <SEO />

    <GlobalStyle />

    <HomeContainer>
      <HomeContentContainer>
        <MapletonLogoImage src={MapletonLogo} alt="The Mapleton Estate Logo" />
        <ComingSoon />
      </HomeContentContainer>
    </HomeContainer>
  </>
)

export default App
