import React from "react"

import {
  ComingSoonContainer,
  ComingSoonHorizontalLine,
  ComingSoonText
} from "./ComingSoon.styles"

const ComingSoon = () => (
  <ComingSoonContainer>
    <ComingSoonHorizontalLine />
    <ComingSoonText>COMING SOON</ComingSoonText>
    <ComingSoonHorizontalLine />
  </ComingSoonContainer>
)

export default ComingSoon
