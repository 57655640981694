import styled from "styled-components"

export const ComingSoonContainer = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
`

export const ComingSoonHorizontalLine = styled.div`
  flex: 2;
  width: 100%;
  height: 2px;
  background-color: white;
`

export const ComingSoonText = styled.h1`
  flex: 1;
  margin: 20px;
  user-select: none;
  letter-spacing: 8px;
  text-align: center;
  font-size: 30px;
  font-weight: 300;
  color: white;

  @media only screen and (max-width: 440px) {
    font-size: 21px;
  }
`
