import styled from "styled-components"

import MapletonBlurBG from "../images/mapleton-blur-background.png"

export const HomeContainer = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-image: url(${MapletonBlurBG});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

export const HomeContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const MapletonLogoImage = styled.img`
  display: block;
  width: 380px;
  user-select: none;

  @media only screen and (max-width: 440px) {
    width: 80%;
  }
`
