import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, pathname }) => {
  return (
    <StaticQuery
      query={seoQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        const metaUrl = `${data.site.siteMetadata.siteUrl}${pathname}`
        const metaImage = `${data.site.siteMetadata.siteUrl}/mapleton-share-image.jpg`

        return (
          <Helmet
            title={
              title
                ? `${title} | ${data.site.siteMetadata.title}`
                : `${data.site.siteMetadata.title}`
            }
          >
            <meta
              name="title"
              content={
                title
                  ? `${title} | ${data.site.siteMetadata.title}`
                  : `${data.site.siteMetadata.title}`
              }
            />
            <meta name="description" content={metaDescription} />

            <meta property="og:type" content="website" />
            <meta property="og:url" content={metaUrl} />
            <meta
              property="og:title"
              content={
                title
                  ? `${title} | ${data.site.siteMetadata.title}`
                  : `${data.site.siteMetadata.title}`
              }
            />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:image" content={metaImage} />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={metaUrl} />
            <meta
              property="twitter:title"
              content={
                title
                  ? `${title} | ${data.site.siteMetadata.title}`
                  : `${data.site.siteMetadata.title}`
              }
            />
            <meta property="twitter:description" content={metaDescription} />
            <meta property="twitter:image" content={metaImage} />
          </Helmet>
        )
      }}
    />
  )
}

export default SEO

const seoQuery = graphql`
  query seoQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`
